:root {
  --Background: #e2e1e1;
  --Light: #fff;
  --Gold: #e9ae0c;
  --LightGold: #f7d57a;
  --FontColor: #010101;
  --LightBlue: #2a4e88;
  --Gray: #2d2d2d;
  --DarkBlue: rgb(18, 39, 87);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--Background);
  background-image: radial-gradient(rgba(0, 0, 0, 0.301) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: center;
  background-attachment: fixed;
}
h1 {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
h2 {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
a {
  font-size: 1.3em;
  text-decoration: none;
  color: var(--FontColor);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sidePage {
  margin: 8% auto;
  max-width: 1200px;
}
li {
  list-style-type: none;
}
.divider {
  width: 100%;
  height: 3px;
  background-color: var(--Gold);
}
ul {
  padding: 0;
}
button {
  font-size: 1.3em;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");
/* ------ Nav Bar.js-------- */
.Nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-image: linear-gradient(#f1f0f1ab, #f1f0f164);
  padding: 2px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--FontColor);
  border-style: solid;
  border-width: 1px;
}
.Nav nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.Nav nav ul li a {
  color: var(--Light);
  display: inline-block;
  margin-right: 10px; /* Adjust as needed */
  background-color: var(--LightBlue);
  min-width: 80px;
  padding: 7px 15px;
  margin: 10px;
  border-style: solid;
  border-radius: 30px;
  text-align: center;
}
.Nav nav ul li a:hover {
  background-color: var(--Light);
  color: var(--FontColor);
}
.Nav nav ul li:last-child {
  margin-right: 0;
}

/* ----- Footer.js ------*/

.Footer {
  display: flex;
  justify-content: space-between;
  font-size: smaller;
  padding: 1%;
  border-style: solid;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 1%;
  background-color: var(--Background);
}
.Footer h3 {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

/* ---- Home.js ----- */

.home {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Header {
  padding-top: 8%;
  min-height: 20vh;
  color: var(--FontColor);
}
.about {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1em;
  padding: 2%;
  margin: auto 3%;
  border-style: solid;
  align-items: center;
  overflow: hidden;
  border-radius: 30px;
  padding: 3%;
  max-width: 1200px;
  box-shadow: 10px 10px 2px 1px var(--LightBlue);
  border-style: solid;
  background-color: var(--Background);
}
.about a {
  font-size: 1em;
  color: var(--FontColor);
}
.about a:hover {
  color: var(--Gold);
}
.recipeImageSlide {
  display: flex;
  width: 120%;
  animation: slide 3.5s linear infinite alternate;
}
@keyframes slide {
  from {
    transform: translate(-90px, 0);
  }
  to {
    transform: translate(90px, 0);
  }
}
/* content */
.content {
  min-height: 400px;
  margin: 3% auto;
  background-color: var(--LightBlue);
  color: var(--Light);
  border-radius: 30px;
  padding: 3%;
  max-width: 1200px;
  box-shadow: 10px 10px 2px 1px var(--Gold);
  border-style: solid;
}
/* -------- Search --------- */

.search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.searchBar {
  width: 50%;
  padding: 8px 22px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--FontColor);
  border-radius: 20px;
  box-shadow: 5px 5px 2px 1px var(--Gold);
  transition: 0.3s;
}

.search button,
.custom-select {
  background-color: var(--Light);
  border-style: solid;
  border-color: var(--FontColor);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 20px;
  padding: 8px 20px;
  margin-left: 20px;
  box-shadow: 5px 5px 2px 1px var(--Gold);
}

.search button:hover,
.custom-select:hover,
.searchBar:hover {
  border-color: var(--FontColor);
  box-shadow: 0px 0px 1px 8px var(--Gold);
}
.custom-select {
  border-width: 2px;
}
.no-recipes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.no-recipes-found {
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
  font-family: "Arial", sans-serif;
  font-weight: bold;
}

/* -------- Results --------- */

.results ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.results li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  margin: 3%;
  width: 350px;
  text-align: center;
  border-color: var(--FontColor);
  border-style: solid;
  border-radius: 20px;
  background-color: var(--Light);
  color: var(--FontColor);
  box-shadow: 10px 10px 2px 1px var(--Gold);
  transition: 0.3s;
}
.results li:hover {
  box-shadow: -10px 10px 2px 1px var(--Gold);
}
.results li img {
  border-style: solid;
  border-width: 2px;
}
.recipeOptionButtons {
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.recipeOptionButtons :nth-child(1),
.recipeOptionButtons button {
  border-style: solid;
  border-radius: 20px;
  background-color: var(--Light);
  padding: 1% 3%;
  border-color: var(--Gray);
  transition: 0.3s;
  margin: auto 20px;
  cursor: pointer;
}
.recipeOptionButtons :nth-child(1):hover,
.recipeOptionButtons button:hover {
  box-shadow: 3px 3px 2px 1px var(--DarkBlue);
}

/* ------ History Page ----- */
.HistoryWhole {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding: 2%;
  margin: 100px auto;
  background-color: #fffff0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.HistoryWhole > h1 {
  text-align: center;
  margin-top: 20px;
}
.recipeHistoryCard {
}
.recipeHistoryCardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  padding: 2%;
}
.historyimage-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.historyimage {
  background-color: var(--LightBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: auto;
  max-width: 540px;
  min-height: 300px;
  flex-direction: column;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--Light);
  box-shadow: 10px 10px 2px 1px var(--Gold);
  transition: 0.3s;
  padding: 10px;
}

.historyimage img {
  width: 75%;
  max-width: 500px;
  height: auto;
  border: 4px solid #ffeb3b;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.historyimage:hover {
  box-shadow: -10px 10px 2px 1px var(--Gold);
}

.historytitle {
  font-size: 2rem;
  color: #333;
  padding: 20px;
  text-align: center;
}
.sourceLink {
  display: none;
}
.historyButton {
  background-color: var(--Light);
  border-style: solid;
  border-color: var(--FontColor);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 20px;
  padding: 8px 20px;
  margin: 15px;
  box-shadow: 5px 5px 2px 1px var(--Gold);
  width: 100px;
}
.historyButton:hover {
  border-color: var(--FontColor);
  box-shadow: 0px 0px 1px 8px var(--Gold);
}

.recipe-summary {
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: var(--LightBlue);
  border-radius: 20px;
  border: 2px solid var(--Gold);
  box-shadow: 10px 10px 2px 1px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.recipe-servings,
.recipe-ready-in {
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: var(--LightBlue);
  border-radius: 20px;
  border: 2px solid var(--Gold);
  padding: 10px 20px;
  margin: 10px 0;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.1);
  width: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.recipe-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2%;
}

.printButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* ------- View Page ---------- */

.viewRecipe {
  padding-right: 3%;
  padding-left: 3%;
  min-height: 90vh;
}
.recipeTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}
.imgWithIngredients {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 2%;
  flex-wrap: wrap;
}
.imgWithIngredients img {
  border-style: solid;
  border-width: 4px;
  border-radius: 20px;
}
.ingredients li {
  list-style-type: circle;
}
.ingredients {
  background-color: var(--LightGold);
  background-size: 20px 20px;
  background-image: linear-gradient(
    to bottom,
    rgba(128, 128, 128, 0.253) 1px,
    transparent 1px
  );
  padding: 3%;
  border-style: solid;
  border-width: 10px;
  border-radius: 20px;
  border-left: 0;
  border-right: 0;
  margin: 2%;
}
.ingredients li {
  font-weight: 600;
}
/* ------- Create page-------- */

.CreateRecipePage {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
}
.createTitle {
  padding: 150px 2% 0px;
}
.createRecipeCard {
  background-color: var(--LightBlue);
  min-height: 300px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--Light);
  box-shadow: 10px 10px 2px 1px var(--Gold);
  transition: 0.3s;
  padding: 20px;
}
.createRecipeCard h3 {
  color: var(--Light);
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
}

.my-1 input {
  width: 190px;
  background-color: var(--Light);
  padding: 1%;
}

/* .createRecipeCard:hover {
  box-shadow: -10px 10px 2px 1px var(--Gold);
} */

.createRecipeButton {
  background-color: var(--Light);
  border-style: ridge;
  border-color: var(--DarkBlue);
  cursor: pointer;
  transition: 0.3s;
  border-radius: 10px;
  padding: 3%;
  box-shadow: 5px 5px 2px 1px var(--Gold);
  transition: 0.3s;
  margin-top: 3%;
}
.createRecipeButton:hover {
  box-shadow: 0px 0px 1px 5px var(--Gold);
}
.form-control {
  margin: 2%;
  padding: 2%;
  border-style: inherit;
  border-radius: 2px;
}
.summaryCreate textarea {
  min-width: 190px;
  padding: 1%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
